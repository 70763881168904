<template>
  <file-pond
    name="file"
    :server="server"
    :accepted-file-types="acceptedFileTypes"
    credits="false"
    :labelIdle="label"
    maxFiles="1"
    :files="files"
    :onaddfile="onaddfile"
    :onremovefile="onremovefile"
  />
</template>

<script>
import { getApiUrl } from "@/utils";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview
);

export default {
  components: {
    FilePond,
  },

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
    },
    type: {
      type: String,
      required: false,
      default: "video",
    },
  },

  data() {
    return {
      files: [],
      file: null,
      isEditing: false,
      hasChanged: false,
      hasDeleted: false,
    };
  },

  computed: {
    acceptedFileTypes() {
      let fileType = "video/mp4";

      if (this.type === "audio") {
        fileType = "audio/*";
      }

      return fileType;
    },
    server() {
      const appId = this.$route.params.id;
      const testimonialId = this.$route.params.testimonialId;
      const token = localStorage.getItem("token");

      return {
        url: `${getApiUrl(
          appId
        )}/module/includem/testimonials/${testimonialId}/file`,
        process: null,
        revert: null,
        remove: null,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    },
  },

  methods: {
    setFile(file) {
      this.isEditing = true;

      this.files.push({
        source: file,
        options: {
          type: "local",
        },
      });
    },
    hasFile() {
      if (this.isEditing === true && this.hasChanged === false) {
        return false;
      }

      return this.file !== null;
    },
    isDeleted() {
      return (
        this.isEditing === true &&
        this.file === null &&
        this.hasDeleted === true
      );
    },
    getFile() {
      return this.file;
    },
    onaddfile(error, file) {
      if (error) {
        return;
      }

      if (this.isEditing) {
        this.hasChanged = true;
      }

      this.file = file.file;
    },
    onremovefile() {
      this.file = null;
      this.files = [];
      this.hasDeleted = true;
    },
  },
};
</script>
