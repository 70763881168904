<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Testimonial</v-card-title
      >
      <v-divider></v-divider>

      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="testimonial-form">
          <v-text-field
            label="Title *"
            v-model="fields.title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-text-field
            label="Subtitle *"
            v-model="fields.subtitle"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('subtitle')"
            :error-messages="errors['subtitle']"
          ></v-text-field>
          <FileUpload
            ref="audio"
            label='<b>Audio File</b><br />Drag & Drop your audio file or <span class="filepond--label-action"> Browse </span>'
            type="audio"
          />
          <v-textarea
            label="Description *"
            v-model="fields.description"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>

          <v-select
            :items="images"
            label="Graphic"
            item-text="url"
            item-value="id"
            v-model="fields.graphic"
            outlined
            background-color="white"
          >
            <template v-slot:selection="{ item, index }">
              <img width="48" :src="item.asset_urls.url" />
            </template>
            <template v-slot:item="{ item }">
              <img width="48" :src="item.asset_urls.url" />
            </template>
          </v-select>

          <v-file-input
            v-model="fields.image"
            accept="image/png"
            placeholder="Choose a Illustration Image"
            prepend-icon="mdi-camera"
            label="Image *"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('image')"
            @change="imageHasChanged()"
            :error-messages="errors['image']"
          ></v-file-input>

          <v-text-field
            label="Order *"
            v-model="fields.order"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('order')"
            :error-messages="errors['order']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="testimonial-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "../components/FileUpload";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      audioFiles: [],
      audioFile: null,
      audioChanged: false,
      audioDeleted: false,
      fields: {
        title: null,
        audio: null,
        subtitle: null,
        description: null,
        graphic: null,
        image: null,
        order: null,
        image_has_changed: false,
        audio_has_changed: false,
      },
      testimonial: null,
      errors: {},
    };
  },

  computed: {
    images() {
      return this.$store.getters["includem/testimonialsStore/allImages"];
    },
  },

  methods: {
    openForm: function (testimonial = null) {
      if (testimonial !== null) {
        this.isEditing = true;
        this.testimonial = testimonial;
        this.fields.title = testimonial.title;
        this.fields.subtitle = testimonial.subtitle;
        this.fields.description = testimonial.description;

        this.fields.order = testimonial.order;

        if (testimonial.graphic) {
          this.fields.graphic = testimonial.graphic_image;
        }
        if (testimonial.audio) {
          //  this.$refs.audio.setFile(testimonial.audio);
        }

        this.fields.image = testimonial.image
          ? new File([testimonial.image.url], testimonial.image.url)
          : null;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.$refs.audio.setFile(null);

      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.testimonial = null;
      this.fields.audio = null;
      this.fields.title = null;
      this.fields.subtitle = null;
      this.fields.description = null;
      this.fields.graphic = null;
      this.fields.order = null;
      this.fields.image = null;
      this.errors = {};
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.testimonialId = this.testimonial.id;
      }

      // let formData = new FormData();
      if (this.$refs.audio.hasFile()) {
        //  formData.append("audio", this.$refs.audio.getFile());

        this.fields.audio = this.$refs.audio.getFile();
      }

      this.$store
        .dispatch("includem/testimonialsStore/saveTestimonial", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    imageHasChanged: function () {
      this.fields.image_has_changed = true;
    },

    audioHasChanged: function () {
      this.fields.audio_has_changed = true;
    },
  },
};
</script>
